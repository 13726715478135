import React, { useState, useEffect } from "react";
import styles from "./CoolLandingPage.module.css";
import { Home } from "lucide-react";

const gradientOptions = {
  purple: styles.gradientPurple,
  blue: styles.gradientBlue,
  brown: styles.gradientBrown,
  green: styles.gradientGreen,
  yellow: styles.gradientYellow,
};

const CoolLandingPage = ({
  logo,
  title,
  description,
  subtitle,
  loadingTime = 2000,
  logoSize = "12rem",
  gradient = "purple",
  homeLink,
}) => {
  const [logoError, setLogoError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [contentVisible, setContentVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
      setTimeout(() => setContentVisible(true), 50);
    }, loadingTime);

    return () => clearTimeout(timer);
  }, [loadingTime]);

  const handleLogoError = () => {
    setLogoError(true);
  };

  const gradientClass =
    gradientOptions[gradient.toLowerCase()] || gradientOptions.purple;

  console.log("Current gradient:", gradient); // Debug log

  const handleClick = (e) => {
    if (!e.ctrlKey && !e.metaKey) {
      e.preventDefault();
      window.location.href = homeLink;
    }
  };

  return (
    <div
      className={`${styles.container} ${
        isLoading ? styles.containerLoading : gradientClass
      }`}
    >
      {isLoading ? (
        <div
          className={styles.contentContainer}
          style={{ justifyContent: "center", paddingTop: 0 }}
        >
          <div className={styles.spinner}></div>
        </div>
      ) : (
        <>
          <div className={styles.logoContainer}>
            {logoError ? (
              <div
                className={styles.logoFallback}
                style={{ width: logoSize, height: logoSize }}
              >
                monjo.co
              </div>
            ) : (
              <img
                src={logo}
                alt="Logo"
                className={styles.logo}
                style={{ width: logoSize }}
                onError={handleLogoError}
              />
            )}
            {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
          </div>
          <div className={styles.contentContainer}>
            <div
              className={`${styles.content} ${
                contentVisible ? styles.contentVisible : ""
              }`}
            >
              <div className={styles.contentShape}>
                <h1 className={styles.title}>{title}</h1>
                <p> &nbsp; </p>
                <p className={styles.description}>{description}</p>
                <p> &nbsp; </p>
                <div className={styles.homeLink}>
                  <a href={homeLink} onClick={handleClick}>
                    <Home size={26} />
                    &nbsp;&nbsp;&nbsp;
                    <span> Go back Home</span>
                    &nbsp;
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CoolLandingPage;
