import React from "react";
import { Helmet } from "react-helmet";
import CoolLandingPage from "./CoolLandingPage";
import logoWhite from "./Monjo_White.png";

function App() {
  console.log("Gradient from env:", process.env.REACT_APP_GRADIENT); // Debug log

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_SUBTITLE}</title>
        <meta
          name="description"
          content={
            process.env.REACT_APP_HOME_LINK +
            " " +
            process.env.REACT_APP_SUBTITLE
          }
        />
      </Helmet>
      <CoolLandingPage
        logo={logoWhite}
        subtitle={process.env.REACT_APP_SUBTITLE}
        title={process.env.REACT_APP_TITLE}
        description={process.env.REACT_APP_DESCRIPTION}
        loadingTime={parseInt(process.env.REACT_APP_LOADING_TIME, 10)}
        logoSize={process.env.REACT_APP_LOGO_SIZE}
        gradient={process.env.REACT_APP_GRADIENT || "purple"}
        homeLink={process.env.REACT_APP_HOME_LINK}
      />
    </>
  );
}

export default App;
